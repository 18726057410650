import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "landing-pages"
    }}>{`Landing Pages`}</h1>
    <p>{`Your estore is like the front shop window of your business. You need to make it as impressive as possible to attract buyers. `}</p>
    <p><strong parentName="p">{`Creating a Landing Page:`}</strong></p>
    <p>{`To create a new Landing Page for your store, `}</p>
    <ul>
      <li parentName="ul">{`Go to Marketing -- Landing Pages`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`New Site`}</em></li>
      <li parentName="ul">{`Enter the Name and Description`}</li>
      <li parentName="ul">{`Upload the JSON in Trunkroad format to configure all the different sections in the site.`}</li>
      <li parentName="ul">{`Under this, you will find a box to drag and drop your logo, according to which the colors of your site will be automatically taken.`}</li>
      <li parentName="ul">{`In the Color Palette, you can always change the colors as per your preference.`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`Save`}</em></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      