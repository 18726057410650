import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "catalog"
    }}>{`Catalog`}</h1>
    <p>{`The Catalog Menu provides quick access to Product creation, Categories, Collections, and Product families.`}</p>
    <p><strong parentName="p">{`Catalog Menu:`}</strong></p>
    <p>{`On the Admin sidebar, click `}<em parentName="p">{`Catalog`}</em>{`.`}</p>
    <p><strong parentName="p">{`Products:`}</strong></p>
    <p>{`On the Products page, you can reate new products of each sort and manage their categories, attributes, etc.`}</p>
    <p><strong parentName="p">{`Creating a Product:`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`In order to create a new product, click `}<em parentName="p">{`New Product`}</em>{` on the right side of your screen.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You will now see the New Product screen where you'll be able to give details like Name, Title, Description, Currency Code, and product Family (learn more about Product Families here) while you create a new product.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`There needs to be at least one variant per product. Product Variants are of different colours, types, etc. For example, if there is a Full sleeves shirt, there can be variants of the same like 'Chinese collar' 'Regular collar', etc.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you're simply starting out, you'll be able to produce some sample products to experiment with every field. For a deeper understanding of products, learn about `}<em parentName="p">{`Catalog Navigation`}</em>{` (read more) through `}<em parentName="p">{`Categories`}</em>{` and configuring `}<em parentName="p">{`Product Attributes`}</em>{` and `}<em parentName="p">{`Families`}</em>{`.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Uploading Product Images and Videos:`}</strong></p>
    <p>{`Using high-quality pictures of consistent proportion offers your catalog a professional look with a commercial appeal.`}</p>
    <p>{`If you have a large catalog with many pictures per product, you'll end up with hundreds, if not thousands, of product pictures to manage. `}</p>
    <p>{`So before you start, it is recommended to establish a naming convention for your image files, and organize them thus so you will be able to retreive the originals whenever you need them.`}</p>
    <p>{`For each product, you'll be able to upload multiple pictures, arrange their order, and manage how every image is used. If you have too many pictures to manage, you may opt to import them in bulk, instead of one by one.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      