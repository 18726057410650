import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "customers"
    }}>{`Customers`}</h1>
    <p>{`Customers are buyer accounts who buy products from your estore. By giving their email addresses, you can also create a login for them so they can use the same email id when they login to your estore. Giving their Tax Identification, Customer Group, and Payment Terms will make sure that the Order that they place will automatically have all of these details.`}</p>
    <p><strong parentName="p">{`Creating a New Customer:`}</strong></p>
    <ul>
      <li parentName="ul">{`Go to `}<em parentName="li">{`Customers`}</em>{` under `}<em parentName="li">{`Customers`}</em>{`.`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`New customer`}</em>{`.`}</li>
      <li parentName="ul">{`Enter your Customer's Name, Email, Description (what kind of customer they are), Tax Identification (GSTIN, TIN, VAT), Customer Group, and Payment Terms`}</li>
      <li parentName="ul">{`Set the Active button below to `}<em parentName="li">{`Yes`}</em>{`.`}</li>
      <li parentName="ul">{`Scroll down and click `}<em parentName="li">{`Add Address`}</em>{` under Addresses.`}</li>
      <li parentName="ul">{`Give all the Address details, and add additional emails and phone numbers if needed. Click `}<em parentName="li">{`Save`}</em>{`.`}</li>
      <li parentName="ul">{`You can add multiple addresses in the same manner.`}</li>
      <li parentName="ul">{`Once done, `}<em parentName="li">{`Save`}</em>{` the Customer.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      