import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "what-is-a-product-collection"
    }}>{`What is a Product Collection?`}</h1>
    <p>{`A Collection is group of products that makes it easier for customers to find the products. Here are a few examples of collections that you might create:`}</p>
    <ul>
      <li parentName="ul">{`Clothes for men, women, or children`}</li>
      <li parentName="ul">{`Products of a certain type, such as lamps, cushions, or rugs`}</li>
      <li parentName="ul">{`Products on a certain promotion`}</li>
      <li parentName="ul">{`Products in a certain size or color`}</li>
      <li parentName="ul">{`Seasonal products, such as woolen clothes and raincoats`}</li>
    </ul>
    <p><strong parentName="p">{`Defining Product Collections:`}</strong></p>
    <p>{`To create or edit Product Collections,`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Go to Catalog -- Product Collections.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click `}<em parentName="p">{`New collection`}</em>{` or Edit an existing collection.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Set the collection properties:`}</p>
        <p parentName="li"><strong parentName="p">{`Name`}</strong>{` – specifies the name of the collection that is displayed to the customer.`}</p>
        <p parentName="li"><strong parentName="p">{`Description`}</strong>{` –  allows you to type a text description for the collection.`}</p>
        <p parentName="li"><strong parentName="p">{`Enabled`}</strong>{` – indicates whether the collection is active in your online store. Products cannot be assigned to collections that are disabled (they only appear grayed out in selectors).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click `}<em parentName="p">{`Save`}</em>{`.`}</p>
      </li>
    </ul>
    <p>{`You can now assign products to the defined collections.`}</p>
    <p><strong parentName="p">{`Assigning products to collections:`}</strong></p>
    <p>{`To assign a product to a collection,`}</p>
    <ul>
      <li parentName="ul">{`Go to Products page.`}</li>
      <li parentName="ul">{`Add a new product or select an existing one.`}</li>
      <li parentName="ul">{`Select one of the available options in the Collection field.`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`Save`}</em>{`.`}</li>
    </ul>
    <p>{`The product is now assigned to the selected collection.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      