import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "customer-groups"
    }}>{`Customer Groups`}</h1>
    <p>{`Customer groups determine which Pricelists and Promotions are available for the group. There is one default customer group that gets assigned to customers if no customer group is assigned to them. For example, you can have different Customer Groups based on area, type of customers(such as Wholesalers, Distributors, etc).`}</p>
    <p>{`Please keep in mind that unless the customer is assigned to the relevant Customer Group, you cannot get their particular Pricelist to apply to them.`}</p>
    <p><strong parentName="p">{`Creating a Customer Group:`}</strong>{` `}</p>
    <ul>
      <li parentName="ul">{`Go to Customers -- Customer Group`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`New Customer Group`}</em></li>
      <li parentName="ul">{`Name the Customer Group, enter a Description(optional) and select the Pricelist that you want to be applied to this group.`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`Save`}</em></li>
    </ul>
    <p><strong parentName="p">{`Note:`}</strong>{` It is recommended that you create Customer Groups and Pricelists first and then customers. The flow should ideally be Pricelists -- Customer Groups -- Customers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      