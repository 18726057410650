import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "promotions"
    }}>{`Promotions`}</h1>
    <p>{`Promotions are one of the ways to entice your buyers and get more orders from them. `}</p>
    <p>{`Trunkroad has five kinds of Promotions majorly. These are:`}</p>
    <ul>
      <li parentName="ul">{`Product Discounts`}</li>
      <li parentName="ul">{`Bundle Discounts`}</li>
      <li parentName="ul">{`Buy More Save More`}</li>
      <li parentName="ul">{`Free Shipping`}</li>
      <li parentName="ul">{`Free Giveaway`}</li>
      <li parentName="ul">{`Generate Coupon`}</li>
    </ul>
    <p>{`Let's look at these in detail below.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Product Discounts:`}</strong></li>
    </ul>
    <p>{`These are percentage discounts that can be applied on a group of products. Here, the discount will be applied if the buyer chooses `}<em parentName="p">{`any of`}</em>{` the products on sale. You can schedule it for a certain period and assign it to certain Customers/Customer Groups.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Bundle Discounts:`}</strong></li>
    </ul>
    <p>{`These are percentage discounts that can be applied on a group of products, but only if the products are all purchased together. Here, the discount will be applied if the buyer chooses `}<em parentName="p">{`all of`}</em>{` the products in the bundle. This is an important difference between Product and Bundle Discounts.`}</p>
    <p>{`You can schedule it for a certain period and assign it to certain Customers/Customer Groups.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Buy More Save More:`}</strong></li>
    </ul>
    <p>{`In this type of promotion, you can give a set of products at a discount if a first given set of products is bought. For example you can have 3 kinds of trousers on sale at a certain percentage discount, given that the buyer has already added a given set of shirts to their cart. `}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Free Shipping:`}</strong></li>
    </ul>
    <p>{`You can give Free Shipping on a certain minimum order value. Once the customers/customer groups are specified, this is applied to all those customers.`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Generate Coupon`}</strong></li>
    </ul>
    <p>{`You can generate coupon codes for some customers in your roster. These would mostly be seasonal offers and so they can be scheduled. The options are 'Flat discount on minimum order value' and `}</p>
    <p>{`Coupon generation works in two cases:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Flat discount on minimum order value:`}</strong>{` This discount is applied as a flat value on a minimum order. Just mention coupon code(For e.g., DIWALI200), the minimum order value and discount in amount, and you're good to go.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Percentage Discount:`}</strong>{` If you want a percentage discount to be applied for the coupon you're generating, use this. This can be done by either setting Minimum Order Value and Maximum Discount or both as 'Yes' or 'No'.`}</p>
      </li>
    </ul>
    <p>{`Just generate the coupon as required and market it to your buyer so they will be enticed to buy more.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` There is a concept called "Sort Order" and "Stop Rule Processing" on all discounts. Based on the number you mention in Sort Order, promotions will be applied accordingly. And if you set Stop Rule Processing to Yes, the Sort Order will stop processing at that number.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      