import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "price-lists"
    }}>{`Price Lists`}</h1>
    <p>{`Price Lists are created to mark different prices for different Customers and/or Customer Groups. In order to do this, the admin needs to create Price Lists according to the customer’s name/region name, etc.`}</p>
    <p>{`You can have a Default Pricelist that applies to all customers and the prices that you enter for all the products will be set in this pricelist automatically.`}</p>
    <p>{`In the case of applying a Promotion, the promotion will be applied on top of a Pricelist. That is, if the price of a particular product is Rs 420 in a pricelist, and if a promotion of Rs 100 has been applied, it will be on Rs 420 and not the actual price of the product.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      