import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In this section, you have the option to add users to your company and give them user roles. Mainly, there are three kinds of roles:`}</p>
    <ol>
      <li parentName="ol">{`Business Owner`}</li>
      <li parentName="ol">{`Business User`}</li>
      <li parentName="ol">{`Sales Representative`}</li>
    </ol>
    <p>{`Let us see what these mean:`}</p>
    <p>{`A `}<em parentName="p">{`Business Owner`}</em>{` is the owner of the company and has access to all pages in the application. He/She can CRUD(Create/Read/Update/Delete)
all documents and also create orders from quotations and confirm orders`}</p>
    <p>{`A `}<em parentName="p">{`Business User`}</em>{` is a User that may have access to some or all pages in the application. They have limited CRUD access that is granted by the Business Owner`}</p>
    <p>{`A `}<em parentName="p">{`Sales Representative`}</em>{` is someone who can only create orders for select customers. These people go to the customer's place, negotiate the price and create the order there itself. The orders can be then reviewed by the Admin/Business Owner and then confirmed
To create a new User Role, `}</p>
    <ul>
      <li parentName="ul">{`Go to Users --> User Roles`}</li>
      <li parentName="ul">{`Click on `}<em parentName="li">{`Add Role`}</em></li>
      <li parentName="ul">{`You will now see a popup which lets you add a new user and their respective role. You just have to select the role, provide their name and email id. A mail automatically goes to them to let them log in`}</li>
      <li parentName="ul">{`Once you've added all users with their roles, you can review them by selecting the role in dropdown on this page`}</li>
      <li parentName="ul">{`Only Business Owners can add/edit user roles`}</li>
      <li parentName="ul"></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      