import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "contacts"
    }}>{`Contacts`}</h1>
    <p>{`You can add individual contacts under Customer accounts to manage multiple people that might make purchases from your estore. For example, at Walmart(Customer), let's suppose there are Mr Smith, Ms Poonawala and Mr Singh who are part of their purchasing team. These three need to be added as Contacts under Walmart.`}</p>
    <p>{`Now let us look at how a Contact is created.`}</p>
    <p><strong parentName="p">{`Creating Contacts:`}</strong></p>
    <ul>
      <li parentName="ul">{`Navigate to Customers -- Contacts.`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`New Contact`}</em>{`.`}</li>
      <li parentName="ul">{`Give their details like Name, Email etc and select the Customer account.`}</li>
      <li parentName="ul">{`Set the Active status to `}<em parentName="li">{`Yes`}</em>{`.`}</li>
      <li parentName="ul">{`Add addresses as needed.`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`Save`}</em>{`.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      