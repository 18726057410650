import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "product-attributes"
    }}>{`Product Attributes`}</h1>
    <p>{`Attributes are the building blocks of your product catalog, and describe specific characteristics of a product, like size, color, etc. `}</p>
    <p>{`Attributes determine the type of input control that is used for product options, provide additional information on your product pages, and are used as search parameters and criteria for layered navigation, product comparisons, and for promotions. You can create as many attributes as necessary to describe the products in your catalog. `}</p>
    <p><strong parentName="p">{`Creating Product Attributes:`}</strong></p>
    <p>{`You can create attributes directly from the Product Attributes page. The following steps show how to create attributes from the menu.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`On the Admin sidebar, go to Catalog -- Product Attributes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click `}<em parentName="p">{`New Product Attribute`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In the New Attribute details, enter a Name and Label to identify the attribute. Also enter the description(optional) to describe the attribute in brief.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In Type, specify the Input Type for Store Owner be used for data entry. These can be Big Decimal, Boolean, Currency, etc according to the attribute.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In the options below, if you want the attribute to be Enabled/Visible/Searchable/Sortable/Filterable, set the value to Yes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click `}<em parentName="p">{`Save`}</em>{`.`}</p>
      </li>
    </ul>
    <p>{`Once the Attribute is created, assign it to a Product Family. Learn more about Product Families `}<em parentName="p">{`here`}</em>{`.`}</p>
    <p><strong parentName="p">{`Best Practices for Product Attributes:`}</strong></p>
    <p><strong parentName="p">{`Attribute Names`}</strong>{`:
Maintain consistent attribute naming conventions, including case and punctuation. For example, Color: Ocean Blue and Color: Ocean_Blue, and Color: Ocean-Blue might be considered as three different attribute values by the system. Such confusion in the data can affect business rules, search results and data filters for the application.`}</p>
    <p><strong parentName="p">{`Attribute Use`}</strong>{`:
Consider how attributes are to be used when assigning properties and values. Identify the attributes that are used as labels for presentation, such as a title or product name, image, price, and description, and which attributes are used for data entry. Consider how the attributes are represented on different pages throughout the site, and how they appear on category pages, product detail pages, and other places.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      