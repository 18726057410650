import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "quotations"
    }}>{`Quotations`}</h1>
    <p>{`When a customer creates an RFQ (Request for Quotation), you can see it on your Admin Menu under Sales. A Quotation can be then created and sent to the particular customer via email. This Quotation can also be edited, the prices can be negotiated in the Negotiated Price and then changed.`}</p>
    <p><strong parentName="p">{`Negotiating a Quotation:`}</strong></p>
    <ul>
      <li parentName="ul">{`Once you get an RFQ, you will see it in Sales -- Quotations as a Draft Quotation.`}</li>
      <li parentName="ul">{`Upon selecting the particular Quotation you will see a screen where you can edit the details of the Quotation and also enter a 'Negotiated Price'.`}</li>
      <li parentName="ul">{`The Negotiated Price can be saved and then be mailed to the customer (Click `}<em parentName="li">{`Send`}</em>{` on the particular quotation in the listing page).`}</li>
      <li parentName="ul">{`When the customer finally accepts the negotiated price, the quotation can be converted into an order (Click `}<em parentName="li">{`Create Order`}</em>{` .`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      