import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "taxes"
    }}>{`Taxes`}</h1>
    <p>{`This is where you do the basic tax setup for your store, in case of integration with ERPs. You may have as many Tax Configurations as you need, depending on region and currency. Therefore, please make it a point to name these Tax Settings with the region/currency/tax system's names or a combination thereof. For eg., AU-GST can be the Tax Configuration for Australia, UK-VAT can be the Configuration for United Kingdom.`}</p>
    <p><strong parentName="p">{` Creating a Tax `}</strong></p>
    <ul>
      <li parentName="ul">{`Go to Settings -- Taxes`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`New Tax`}</em></li>
      <li parentName="ul">{`Enter the Label, Name, and Description`}</li>
      <li parentName="ul">{`Enter Tax in percentage`}</li>
      <li parentName="ul">{`Set it to Active`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`Save`}</em></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      