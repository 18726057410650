import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "categories"
    }}>{`Categories`}</h1>
    <p>{`Categories are a group of products that can be clubbed together, according to their type, customer demographic, functionality, etc. For example, if you deal in electronics, "home appliances" can be a category, and "washing machines" can be a subcategory.`}</p>
    <p>{`Before adding products to your catalog, you need to establish the basic category structure as shown below. `}</p>
    <p><strong parentName="p">{`Creating Categories:`}</strong></p>
    <p>{`The category structure of your catalog is like a tree, with the root at the top. Each section of the tree can be expanded and collapsed. The first level of categories below the root typically appear as options in the main menu.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`On the Admin sidebar, go to Catalog -- Categories.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In the category tree, tap the parent category of the new category. The parent is one level above the new category.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you’re starting from the beginning without any data, you can add two categories in the list: “Parent Category”, which is the root, and a “Child Category” as the leaf.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click on (+) and (-) to add or remove any subcategories in that particular level.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click `}<em parentName="p">{`Save`}</em>{`.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Category Structure:`}</strong></p>
    <p>{`Your category structure in the main menu can impact customer experience. `}</p>
    <p>{`As a best practice, it is usually recommended to identify one over-arching top-level category, and avoid having other categories with the same name. `}</p>
    <p>{`For example, rather than having multiple categories for “Men” organized under different departments, such as Clothing/Men, Shoes/Men, Coats/Men, it can be more efficient to make the top-level parent category ”Men,” and then create subcategories as needed below. `}</p>
    <p>{`Be consistent with the category structure, and use the same approach for all product types in your catalog.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      