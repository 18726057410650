import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "getting-started-with-your-commerce-hub"
    }}>{`Getting Started with your Commerce Hub`}</h1>
    <p>{`As a Store Admin, you can set up your account by logging in with your desired credentials (Our team would have already helped you with this.).
You can set up products and customers, track orders, and perform other administrative tasks for your company.`}</p>
    <p>{`As soon as you log in, you would see a screen like this:`}</p>
    <p>{`The Admin Sidebar has the following options:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Dashboard`}</strong>{` - The Dashboard provides a quick overview of the sales and customer activity in your store,
and is usually the first page that appears when you log in as an Admin.`}</li>
      <li parentName="ul"><strong parentName="li">{`Sales`}</strong>{` - Here you can see all your Sales related pages like Orders, Price Lists, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`Catalog`}</strong>{` - The Catalog menu enables you to create Products, define Product Categories, Attributes, etc.`}</li>
      <li parentName="ul"><strong parentName="li">{`Customers`}</strong>{` - Set up all your Customers and Customer Groups and assign them Pricelists, Product Lists etc using this menu.`}</li>
      <li parentName="ul"><strong parentName="li">{`Marketing`}</strong>{` - Create Promotions for your customers and sell more.`}</li>
      <li parentName="ul"><strong parentName="li">{`Reports`}</strong>{` - Intelligent Reports to help you gain insights into your Sales patterns.`}</li>
      <li parentName="ul"><strong parentName="li">{`Settings`}</strong>{` - All your company level settings can be configured here.`}</li>
      <li parentName="ul"><strong parentName="li">{`Online Store`}</strong>{` - Set up your online store with customized colours, pages and your own logo.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      