import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sales-orders"
    }}>{`Sales Orders`}</h1>
    <p>{`In Trunkroad, Sales Orders are those which are either placed by your customers through the estore, or those that you or the Sales Rep create with inputs from the buyer. `}</p>
    <p>{`These are the three ways in which a Sales Order can be placed:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`For example, while a customer places some products in their cart from their mobile and places an order, a) they will only see prices that are applicable to them, and b) they will also be able to apply promotions that are meant for them.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`When a Sales Rep does the same, they will be able to select the customer for whom the sale is happening, and create orders for them accordingly.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`You as a Store Admin can also create Sales Orders on your Customers’ behalf. This order can later be mailed to the customer.`}</p>
      </li>
    </ul>
    <p>{`All the orders that are created through these aforementioned ways can be accessed through the Sales Orders page.`}</p>
    <p><strong parentName="p">{`Creating a New Order:`}</strong></p>
    <p>{`Creating an order from the admin portal is pretty straightforward. `}</p>
    <ul>
      <li parentName="ul">{`Go to Sales -- Sales Orders.`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`New Order`}</em>{`.`}</li>
      <li parentName="ul">{`Fill in the required details like Order Number, select the Customer, Contact, and Payment Terms.`}</li>
      <li parentName="ul">{`Mention the Order Date, Currency (will be autopopulated to the Customer's default currency), Purchase Order Number(if any), and Shipment Deadline Date (optional).`}</li>
      <li parentName="ul">{`Add products to the order. Prices will be autofilled according to the customer here.`}</li>
      <li parentName="ul">{`On the bottom left, you will see the Tax and Shipping Summary. You can add any notes if needed, on the bottom right side.`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`Save`}</em>{`.`}</li>
    </ul>
    <p><strong parentName="p">{`Order Flow:`}</strong></p>
    <p>{`Once an order has been created, it goes into "Draft" state by default. The Store Admin has to confirm it for the process to be initiated. All other order statuses (like Completed, Shipped, etc) will be fetched from your ERP software. `}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      