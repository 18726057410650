import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "product-family"
    }}>{`Product Family`}</h1>
    <p>{`A product family is a group of related products in your catalog. The products in a family share common attributes and thus `}<em parentName="p">{`Product Attributes`}</em>{` are assigned directly and are applicable across the family of products.`}</p>
    <p>{`The product family consists of an array of products that are very similar but meet slightly different needs or tastes, potentially attracting more customers.`}</p>
    <p><strong parentName="p">{`Creating a Product Family:`}</strong></p>
    <p>{`A product family can be created only once you have configured your product attributes. It is an absolute prerequisite so you need to keep this in mind. Once the Product Attributes have been created, you can proceed to create Product Family.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`On the Admin sidebar, go to Catalog -- Product Families.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click `}<em parentName="p">{`New Product Family`}</em>{`.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In the New Product Family details, enter a Name and Label to identify the family. Also enter the description(optional) to describe the family in brief.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`In Product Attribute Groups, click `}<em parentName="p">{`Add Input Group`}</em>{`. Specify the Label and Visibilty of the group. Now proceed to select the attributes to add to the Group.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Set the Enabled button below to Yes.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Click `}<em parentName="p">{`Save`}</em>{`.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      