// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-mdx-contacts-mdx": () => import("../src/mdx/contacts.mdx" /* webpackChunkName: "component---src-mdx-contacts-mdx" */),
  "component---src-mdx-customers-mdx": () => import("../src/mdx/customers.mdx" /* webpackChunkName: "component---src-mdx-customers-mdx" */),
  "component---src-mdx-landing-pages-mdx": () => import("../src/mdx/landing_pages.mdx" /* webpackChunkName: "component---src-mdx-landing-pages-mdx" */),
  "component---src-mdx-customer-groups-mdx": () => import("../src/mdx/customer_groups.mdx" /* webpackChunkName: "component---src-mdx-customer-groups-mdx" */),
  "component---src-mdx-master-fields-mdx": () => import("../src/mdx/master_fields.mdx" /* webpackChunkName: "component---src-mdx-master-fields-mdx" */),
  "component---src-mdx-index-mdx": () => import("../src/mdx/index.mdx" /* webpackChunkName: "component---src-mdx-index-mdx" */),
  "component---src-mdx-path-to-purchase-mdx": () => import("../src/mdx/path_to_purchase.mdx" /* webpackChunkName: "component---src-mdx-path-to-purchase-mdx" */),
  "component---src-mdx-payment-terms-mdx": () => import("../src/mdx/payment_terms.mdx" /* webpackChunkName: "component---src-mdx-payment-terms-mdx" */),
  "component---src-mdx-product-categories-mdx": () => import("../src/mdx/product_categories.mdx" /* webpackChunkName: "component---src-mdx-product-categories-mdx" */),
  "component---src-mdx-pricelists-mdx": () => import("../src/mdx/pricelists.mdx" /* webpackChunkName: "component---src-mdx-pricelists-mdx" */),
  "component---src-mdx-product-attributes-mdx": () => import("../src/mdx/product_attributes.mdx" /* webpackChunkName: "component---src-mdx-product-attributes-mdx" */),
  "component---src-mdx-number-sequences-mdx": () => import("../src/mdx/number_sequences.mdx" /* webpackChunkName: "component---src-mdx-number-sequences-mdx" */),
  "component---src-mdx-product-collections-mdx": () => import("../src/mdx/product_collections.mdx" /* webpackChunkName: "component---src-mdx-product-collections-mdx" */),
  "component---src-mdx-products-mdx": () => import("../src/mdx/products.mdx" /* webpackChunkName: "component---src-mdx-products-mdx" */),
  "component---src-mdx-quotations-mdx": () => import("../src/mdx/quotations.mdx" /* webpackChunkName: "component---src-mdx-quotations-mdx" */),
  "component---src-mdx-promotions-mdx": () => import("../src/mdx/promotions.mdx" /* webpackChunkName: "component---src-mdx-promotions-mdx" */),
  "component---src-mdx-sales-orders-mdx": () => import("../src/mdx/sales_orders.mdx" /* webpackChunkName: "component---src-mdx-sales-orders-mdx" */),
  "component---src-mdx-product-families-mdx": () => import("../src/mdx/product_families.mdx" /* webpackChunkName: "component---src-mdx-product-families-mdx" */),
  "component---src-mdx-setting-up-mdx": () => import("../src/mdx/setting_up.mdx" /* webpackChunkName: "component---src-mdx-setting-up-mdx" */),
  "component---src-mdx-user-roles-mdx": () => import("../src/mdx/user_roles.mdx" /* webpackChunkName: "component---src-mdx-user-roles-mdx" */),
  "component---src-mdx-taxes-mdx": () => import("../src/mdx/taxes.mdx" /* webpackChunkName: "component---src-mdx-taxes-mdx" */)
}

