import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "welcome-to-trunkroad"
    }}>{`Welcome to Trunkroad`}</h1>
    <p>{`This is the Trunkroad Documentation`}</p>
    <h1 {...{
      "id": "table-of-contents"
    }}>{`Table of Contents`}</h1>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Introduction`}</strong></li>
    </ul>
    <ul>
      <li parentName="ul">{`Basic Configuration`}</li>
      <li parentName="ul">{`Commerce Hub`}</li>
      <li parentName="ul">{`Sales Rep App`}</li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Sales`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Price Lists`}</li>
          <li parentName="ul">{`Payment Terms`}</li>
          <li parentName="ul">{`Quotes`}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Catalog`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Products`}</li>
          <li parentName="ul">{`Collections`}</li>
          <li parentName="ul">{`Categories`}</li>
          <li parentName="ul">{`Product Families`}</li>
          <li parentName="ul">{`Product Attributes`}</li>
        </ul>
      </li>
    </ul>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Customers`}</strong></p>
        <ul parentName="li">
          <li parentName="ul">{`Customers`}</li>
          <li parentName="ul">{`Contacts`}</li>
          <li parentName="ul">{`Customer Groups`}</li>
        </ul>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      