import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "payment-terms"
    }}>{`Payment Terms`}</h1>
    <p>{`Payment Terms can be set for both parties to agree on while completing an order. These are the terms that the customer has to follow when they need to complete the payment.`}</p>
    <p>{`Terms such as cost, amount, delivery, payment method, and when the payment is expected or due. These are also the essential components of any order/invoice.`}</p>
    <p>{`In short, it’s the expectations between the buyer and seller so that there won’t be any potential misunderstandings nor disagreements because both parties clearly know what is expected and they are satisfied with the requirements.`}</p>
    <p>{`Terms of sale are particularly important in international trade since it covers when shipping occurs, who is responsible for international duties and taxes, and any other factors that have been established by the international chamber of commerce regulations.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      