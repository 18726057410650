import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "number-sequences"
    }}>{`Number Sequences`}</h1>
    <p>{`Documents on Trunkroad, like Orders, Carts, and Quotations can have Number Sequences of their own. These sequences will be automated and you do not have to set them everytime you create a document. Now let us see how Number Sequences can be created.`}</p>
    <p><strong parentName="p">{` Creating a Number Sequence `}</strong></p>
    <ul>
      <li parentName="ul">{`Go to Settings -- Number Sequences`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`New Sequence`}</em></li>
      <li parentName="ul">{`Fill in Name, Prefix, and Start Number. For example, if you want to give a sequence for Sales Orders, give the name as Sales Order Sequence and Prefix as ORD. The Start Number can be 1, and since this is a new sequence the Next Number will automatically be set to 1.`}</li>
      <li parentName="ul">{`Select the Document Type.`}</li>
      <li parentName="ul">{`Set the options below to Yes/No as required.`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`Save`}</em>{`.`}</li>
    </ul>
    <p>{`You have now created a Number Sequence that can be selected via a dropdown on the document.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      