/** @jsx jsx */
import { useConfig, useCurrentDoc } from 'docz';
import { Edit, Menu } from 'gatsby-theme-docz/src/components/Icons';
import { Box, jsx, useColorMode } from 'theme-ui';
import  logo  from '../Logo/logo.png';
import * as styles from './styles';

export const Logo = () => <img style={{height:50, width:50, margin:"1em"}}src={logo} alt="Trunkroad" />
export const Header = props => {
    const { onOpen } = props
    const { edit = true, ...doc } = useCurrentDoc()

    return (
        <div sx={styles.wrapper} data-testid="header">
            <Box sx={styles.menuIcon}>
                <button sx={styles.menuButton} onClick={onOpen}>
                    <Menu size={25} />
                </button>
            </Box>
            <div sx={styles.innerContainer}>
                <Logo />
            </div>
        </div>
    )
}