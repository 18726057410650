import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "path-to-purchase"
    }}>{`Path to Purchase`}</h1>
    <p>{`A Path to Purchase is the path a customer takes that leads to a sale. Here we take a look at pages that have strategic value and those that customers usually visit while shopping in your store. We also look at multiple store features that can be leveraged at each stage of the buyer's journey.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Home Page:`}</strong>{`
Your home page is like the front display of a brick and mortar store. As the primary landing page, its design entices visitors to come inside and take a closer look at your products. It is important to make the design as attractive as possible to leave a lasting impression. `}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Catalog Page:`}</strong>{`
The Catalog page shows products from your catalog in either a grid format. Customers can make selections based on a category they choose from the main menu, or from the results of a search. They can examine the product in better detail or place it directly into their shopping cart.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Search Results:`}</strong>{`
It's common knowledge in B2B segments that people who use search are nearly twice as likely to make a purchase as those who rely on store navigation and categories alone. You might consider these buyers to be pre-qualified as they already know your products very well.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Product Page:`}</strong>{`
The product page provides detailed information about a specific product in your catalog. Buyers can add the product to their lists, compare it to other products, share it with fellow buyers, and most importantly, add the product into their shopping carts.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Shopping Cart:`}</strong>{`
The shopping cart lists each product by price and quantity selected, and calculates the subtotal. Shoppers can apply promotion coupons, and generate an estimate of shipping and tax charges.`}</p>
      </li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      