import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Master Fields are those that can be used across all page templates (like Product, Customer, Contact, Quotation, and Sales Order). You can directly put fields in Templates automatically from these Master Fields. Now let us see how we can create them.`}</p>
    <p><strong parentName="p">{`Creating Master Fields:`}</strong></p>
    <ul>
      <li parentName="ul">{`Go to Settings -- Master Fields`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`New`}</em></li>
      <li parentName="ul">{`Give a Name and select Type (INT, TEXT, PICK LIST, etc)`}</li>
      <li parentName="ul">{`Set it to Active`}</li>
      <li parentName="ul">{`Click `}<em parentName="li">{`Save`}</em></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      